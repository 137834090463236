import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CsrContractPreDefined, CsrDiscountService} from "@miticon-ui/mc-core";
import {ICON_CLOSE} from "../../../../../assets/media/svg_icons/icon-close";

@Component({
  selector: 'lib-contract-predefined-view-dialog',
  templateUrl: './contract-predefined-view-dialog.component.html',
  styleUrls: ['./contract-predefined-view-dialog.component.scss']
})
export class ContractPredefinedViewDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  discountValue: number;

  constructor(@Inject(MAT_DIALOG_DATA) public csrContractPreDefined: CsrContractPreDefined,
              public dialogRef: MatDialogRef<ContractPredefinedViewDialogComponent>,
              private csrDiscountService: CsrDiscountService) { }

  ngOnInit(): void {
    if(this.csrContractPreDefined.idCsrDiscount) {
      this.csrDiscountService.getById(this.csrContractPreDefined.idCsrDiscount).subscribe((discount) => {
        this.discountValue = discount.value;
      })
    }
  }
}
