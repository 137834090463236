/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProduct} from '../models/mc-product.model';
import {McProductService} from '../services/mc-product.service';
import {IMcProduct} from '../_generated/mc-product.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McCategory} from '../models/mc-category.model';
import {McCurrency} from '../models/mc-currency.model';
import {McVat} from '../models/mc-vat.model';


export abstract class McProductGENERATED extends BaseObject {


    public static readonly PRICING_TYPE_CD_PERCENTAGE: string = 'PERCENTAGE';
    public static readonly PRICING_TYPE_CD_VALUE: string = 'VALUE';
  public static readonly PRICING_TYPE_CD_LIST__ALL = [
    McProductGENERATED.PRICING_TYPE_CD_PERCENTAGE,
    McProductGENERATED.PRICING_TYPE_CD_VALUE
];

    public static readonly REF_TYPE_CD_MC_ENTITY: string = 'MC_ENTITY';
    public static readonly REF_TYPE_CD_CSR_CONSUMER: string = 'CSR_CONSUMER';
  public static readonly REF_TYPE_CD_LIST__ALL = [
    McProductGENERATED.REF_TYPE_CD_MC_ENTITY,
    McProductGENERATED.REF_TYPE_CD_CSR_CONSUMER
];

    public static readonly STATE_ACTIVE: string = 'ACTIVE';
    public static readonly STATE_INACTIVE: string = 'INACTIVE';
  public static readonly STATE_LIST__ALL = [
    McProductGENERATED.STATE_ACTIVE,
    McProductGENERATED.STATE_INACTIVE
];

  public apiService: McProductService;
  public _rawJson: IMcProduct;
    id: number = 0;
  availableFrom: number;
  availableTo: number;
  description = '';
  externalId = '';
  flgArchived: boolean;
  idCurrency: number;
  idMcEntity: number;
  idPpCategory: number;
  idVat: number;
  name = '';
  pricingTypeCd: string;
  refId: number;
  refTypeCd: string;
  state: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  unitPrice: number;
  unitsInStock: number;
  version: number;
  category: McCategory;  currency: McCurrency;  mcCurrency: McCurrency;  mcVat: McVat;  ppCategory: McCategory;  quantity: number;  unitPriceLoc: string;  vat: McVat;  vatRate: any;

  public properties: string[] = ['id', 'availableFrom', 'availableTo', 'description', 'externalId', 'flgArchived', 'idCurrency', 'idMcEntity', 'idPpCategory', 'idVat', 'name', 'pricingTypeCd', 'refId', 'refTypeCd', 'state', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'unitPrice', 'unitsInStock', 'version', 'category', 'currency', 'mcCurrency', 'mcVat', 'ppCategory', 'quantity', 'unitPriceLoc', 'vat', 'vatRate'];
  public propertiesRegular: string[] = ['id', 'availableFrom', 'availableTo', 'description', 'externalId', 'flgArchived', 'idCurrency', 'idMcEntity', 'idPpCategory', 'idVat', 'mcCurrency', 'mcVat', 'name', 'ppCategory', 'pricingTypeCd', 'refId', 'refTypeCd', 'state', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'unitPrice', 'unitsInStock', 'version', 'quantity', 'unitPriceLoc', 'vatRate'];
  public propertiesSpecial: string[] = ['category', 'currency', 'mcCurrency', 'mcVat', 'ppCategory', 'vat'];



  public static getPricingTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProductGENERATED.PRICING_TYPE_CD_PERCENTAGE, McGod.t('Percentage'));
    list.add(McProductGENERATED.PRICING_TYPE_CD_VALUE, McGod.t('Value'));


    return list;
  }

  public static getPricingTypeCdLabel(aCd: string): string {
    const list = McProductGENERATED.getPricingTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getRefTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProductGENERATED.REF_TYPE_CD_MC_ENTITY, McGod.t('Entity'));
    list.add(McProductGENERATED.REF_TYPE_CD_CSR_CONSUMER, McGod.t('Consumer'));


    return list;
  }

  public static getRefTypeCdLabel(aCd: string): string {
    const list = McProductGENERATED.getRefTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStateVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProductGENERATED.STATE_ACTIVE, McGod.t('Active'));
    list.add(McProductGENERATED.STATE_INACTIVE, McGod.t('Inactive'));


    return list;
  }

  public static getStateLabel(aCd: string): string {
    const list = McProductGENERATED.getStateVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McProduct {
    console.error('MTCN-ERROR: Not Implemented: McProduct::createNewInstance(). Add this method to final class and return new McProduct();');
    throw new Error('Cannot McProduct::createNewInstance(). Add this method to final class and return new McProduct();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProduct): McProduct {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProductService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProduct AABB()');
          if (this._rawJson['category']) {
        this.category = McCategory.createFromJson(this._rawJson['category']);
      }
      if (this._rawJson['currency']) {
        this.currency = McCurrency.createFromJson(this._rawJson['currency']);
      }
      if (this._rawJson['mcCurrency']) {
        this.mcCurrency = McCurrency.createFromJson(this._rawJson['mcCurrency']);
      }
      if (this._rawJson['mcVat']) {
        this.mcVat = McVat.createFromJson(this._rawJson['mcVat']);
      }
      if (this._rawJson['ppCategory']) {
        this.ppCategory = McCategory.createFromJson(this._rawJson['ppCategory']);
      }
      if (this._rawJson['vat']) {
        this.vat = McVat.createFromJson(this._rawJson['vat']);
      }

  }

  // ---------------------------------------------------------------------

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getUnitPriceStr(): string {
       return McGod.formatNumberToMoney(this.unitPrice);
    }

    public getVatRateStr(): string {
       return McGod.formatNumberToMoney(this.vatRate);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('category')) {
        if (this.category != null) {
            dto['category'] = this.category.toDto();
        } else {
            dto['category'] = null;
        }
    }
    if (this.hasOwnProperty('currency')) {
        if (this.currency != null) {
            dto['currency'] = this.currency.toDto();
        } else {
            dto['currency'] = null;
        }
    }
    if (this.hasOwnProperty('mcCurrency')) {
        if (this.mcCurrency != null) {
            dto['mcCurrency'] = this.mcCurrency.toDto();
        } else {
            dto['mcCurrency'] = null;
        }
    }
    if (this.hasOwnProperty('mcVat')) {
        if (this.mcVat != null) {
            dto['mcVat'] = this.mcVat.toDto();
        } else {
            dto['mcVat'] = null;
        }
    }
    if (this.hasOwnProperty('ppCategory')) {
        if (this.ppCategory != null) {
            dto['ppCategory'] = this.ppCategory.toDto();
        } else {
            dto['ppCategory'] = null;
        }
    }
    if (this.hasOwnProperty('vat')) {
        if (this.vat != null) {
            dto['vat'] = this.vat.toDto();
        } else {
            dto['vat'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --


  public getPricingTypeCdLabel(): string {
    return McProductGENERATED.getPricingTypeCdLabel(this.pricingTypeCd);
  }


 public  isPricingTypeCdPERCENTAGE(): boolean {
    const result = (this.pricingTypeCd === McProductGENERATED.PRICING_TYPE_CD_PERCENTAGE);

    return result;
  }

 public  isPricingTypeCdVALUE(): boolean {
    const result = (this.pricingTypeCd === McProductGENERATED.PRICING_TYPE_CD_VALUE);

    return result;
  }


  public getRefTypeCdLabel(): string {
    return McProductGENERATED.getRefTypeCdLabel(this.refTypeCd);
  }


 public  isRefTypeCdMC_ENTITY(): boolean {
    const result = (this.refTypeCd === McProductGENERATED.REF_TYPE_CD_MC_ENTITY);

    return result;
  }

 public  isRefTypeCdCSR_CONSUMER(): boolean {
    const result = (this.refTypeCd === McProductGENERATED.REF_TYPE_CD_CSR_CONSUMER);

    return result;
  }


  public getStateLabel(): string {
    return McProductGENERATED.getStateLabel(this.state);
  }


 public  isStateACTIVE(): boolean {
    const result = (this.state === McProductGENERATED.STATE_ACTIVE);

    return result;
  }

 public  isStateINACTIVE(): boolean {
    const result = (this.state === McProductGENERATED.STATE_INACTIVE);

    return result;
  }



  // ---------------------------------------------------------------------
  public archive( id: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.archive(id)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
