/*BbmTestObj*/
import {McProductGENERATED} from '../_generated/mc-product-generated.model';
import {McDateUtils} from '../common/mc-date-utils';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {CurrencyUtil} from '../common/currency-util';
import {ActionOptions} from '../_core/mc-html-action-options';
import {McValueLabelList} from '../_core/mc-value-label-list';

export class McProduct extends McProductGENERATED {

  public static ACTION_SHOW_PROFILE = 'PROFILE';
  public static ACTION_SHOW_EDIT = 'EDIT';
  public static ACTION_SHOW_DELETE = 'DELETE';
  public static ACTION_COPY = 'COPY';
  public static ACTION_MAX = 'MAX';
  public static ACTION_PRODUCT_UP = 'ACTION_PRODUCT_UP';
  public static ACTION_PRODUCT_DOWN = 'ACTION_PRODUCT_DOWN';
  public static ACTION_PRODUCT_REMOVE = 'ACTION_PRODUCT_REMOVE';


  private _fldEditLink = null;
  private _fldName: McHtml | null = null;

  private _fldActions: McHtml | null = null;

  vatRate!: number;
  noOfUnitsSelected = 1;
  currencySymbol!: string;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McProduct();
  }

  fldActions() {
    // this.router.navigate([`/admin/entity/${entityId}/management/consumers/edit/${content.id}`], {queryParams: {activeTab: "paymentInfoTab"}});

//    return "dasa je car";

    if (!this._fldActions) {
      const html = new McHtml();

      const actSet = html.addActionSet();
      actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${McGod.t('cc.out-factoring.view-product')}`, McProduct.ACTION_SHOW_PROFILE, this, [McGod.PERM_PP_PRODUCT_VIEW], '', ActionOptions.OPTION_VIEW);
      actSet.addActionB(`<i class="fa mc-submit-color fa-pen px-1 mc-cursor-pointer"></i> ${McGod.t('cc.out-factoring.edit-product')}`, McProduct.ACTION_SHOW_EDIT, this, [McGod.PERM_PP_PRODUCT_VIEW], '', ActionOptions.OPTION_EDIT);
      actSet.addActionB(`<i class="fas mc-submit-color fa-trash-alt px-1 mc-cursor-pointer"></i> ${McGod.t('cc.common.delete')}`, McProduct.ACTION_SHOW_DELETE, this, [McGod.PERM_PP_PRODUCT_VIEW], '', ActionOptions.OPTION_DELETE);
      this._fldActions = html;
    }

    return this._fldActions;
  }

  // ---------------------------------------------------------------------
  fldAvailableFrom() {
    return McDateUtils.newFormatDateString(this.availableFrom);
  }

  fldAvailableTo(): string {
    if (this.availableTo) {
      return McDateUtils.newFormatDateString(this.availableTo);
    }
    return '';
  }

  fldName() {
    if (!this._fldName) {
      const html = new McHtml();
      html.addTooltipB(this.name, this.description, 'right');
      this._fldName = html;
    }
    return this._fldName.items;
  }

  fldVatRate() {
    return `${this.mcVat.vatRate.toFixed(2).replace('.', ',')}%`;
  }

  fldCategory() {
    if (!this.ppCategory) {
      return McGod.t('cc.product.uncategorized');
    }
    return this.ppCategory.name;
  }

  fldQuantity() {
    const html = new McHtml();
    html.addButtonB('-', McProduct.ACTION_PRODUCT_DOWN, this, [McGod.PERM_MC_PROCESS_FLOW_VIEW], 'btn btn-outline-primary mr-2 p-1 mc-html-blue-button', ActionOptions.OPTION_EDIT);
    html.addButtonB('+', McProduct.ACTION_PRODUCT_UP, this, [McGod.PERM_MC_PROCESS_FLOW_VIEW], 'btn btn-outline-primary p-1 mc-html-blue-button', ActionOptions.OPTION_EDIT);
    html.addPlainHtmlB(`<p class="product-quantity p-1 ml-2">${this.noOfUnitsSelected}</p>`);
    return html.items;
  }

  fldRemove() {
    const html = new McHtml();
    html.addButtonB('Remove', McProduct.ACTION_PRODUCT_REMOVE, this, [McGod.PERM_MC_PROCESS_FLOW_VIEW], 'btn btn-outline-secondary mc-html-gray-button', ActionOptions.OPTION_DELETE);
    return html.items;
  }

  fldUnitPriceLoc() {
    if (this.unitPrice) {
      switch (this.pricingTypeCd){
        case McProduct.PRICING_TYPE_CD_VALUE:
          return CurrencyUtil.format(this.unitPrice, this.mcCurrency.code);
        case McProduct.PRICING_TYPE_CD_PERCENTAGE:
          return this.unitPrice + ' %';
        default:
          return this.unitPrice;
      }
    }
    return '0'
  }

  public getReferenceEntitiesVll(parentEntityId: number, externalStudios: boolean): McValueLabelList {
    let entities =  this.apiService.getReferenceEntities(parentEntityId, externalStudios);
    let referenceEntitiesVll = new McValueLabelList();

    entities.subscribe(
      (res) => {
        res.forEach((item: any) => {
          referenceEntitiesVll.add(item.idMcReferenceEntity, item.entityName);
        });
      }
    )

    return referenceEntitiesVll;
  }

}
