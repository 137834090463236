import {Inject, Injectable} from '@angular/core';
import {McProductServiceGENERATED} from '../_generated/mc-product-generated.service';
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";



@Injectable({
  providedIn: 'root'
})
export class McProductService extends McProductServiceGENERATED {

  public getReferenceEntities(parentEntityId: number, externalStudios: boolean): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/csrconsumer/v4/csrconsumer/product-reference-entities/${parentEntityId}`+ `?externalStudios=${externalStudios.toString()}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

}
