<span class="back-option" (click)="goBack()">
  <span [innerHTML]="iconBack | safeHtml"></span>
  {{ "cc.contracts-360.back" | translate }}
</span>
<div class="cardbox">
    <span class="cardbox_title">{{(contractId ? ("cc.common.edit" | translate) : ("cc.common.create" | translate)) + " " + ("cc.contract-360" | translate) | uppercase}}</span>
</div>
<div class='header-info mt-medium'>
  <span class='calculations-card-label'>{{'cc.consumer.total-netto' | translate}}:
    <span class='calculations-card-value'>{{totalNetto ? getTotalNetto().toFixed(2) + '€' : '-'}}</span>
  </span>
  <span class='calculations-card-label'>{{'mem.common.total-brutto' | translate}}:
    <span class='calculations-card-value'>{{totalBrutto ? getTotalBrutto().toFixed(2) + '€' : '-'}}</span>
  </span>
  <span class='calculations-card-label'>{{'cc.common.discount' | translate}}:
    <span class='calculations-card-value'>{{viewDiscount ? ( viewDiscount.value ? viewDiscount.value + '%' : '-') : (discount ? (discount + '%') : '-')}}</span>
  </span>
  <span class='calculations-card-label'>{{'mem.common.price' | translate}}:
    <span class='calculations-card-value'>{{price ? price.toFixed(2) + '€' : '-'}}</span>
  </span>
</div>

<form [formGroup]="form" class='mb-large'>
  <div class="card">
    <!--  Basic informations-->
    <h2 class="title-medium-dark mb-large">{{'cc.common.basic-information' | translate}}</h2>
    <h3 class='title-small-dark mb-small'>{{'cc.common.status' | translate}}*</h3>
    <div class='input-wrapper mb-large'>
      <mat-radio-group formControlName="statusCd">
        <mat-radio-button value="ACTIVE"
                          class='mr-large'
                          (change)="onStatusChange($event)">
          {{'cc.common.view.active' | translate}}
        </mat-radio-button>
        <mat-radio-button value="INACTIVE"
                          (change)="onStatusChange($event)">
          {{'cc.common.view.inactive' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class='input-wrapper mb-small'>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.common.name' | translate}}*</mat-label>
        <input type="text"
               maxlength="22"
               formControlName="name"
               matInput/>
      </mat-form-field>
    </div>
    <div class='input-wrapper-half-width'>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.common.view.description' | translate}}</mat-label>
        <input type="text"
               formControlName="description"
               matInput/>
      </mat-form-field>
    </div>
    <div class="divider"></div>

    <!--  Products  -->
    <h2 class="title-medium-dark mb-large">{{'cc.common.products' | translate}}</h2>
    <ng-container *ngIf='!contractId'>
      <div class='input-wrapper'>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.contract-360.choose-product' | translate}}*</mat-label>
          <mat-select panelClass="matSelect"
                      formControlName="service"
                      disableOptionCentering
                      (selectionChange)="onServiceChange($event.value)">
            <mat-option *ngFor="let product of products" [value]="product.id">
              {{ product.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <div class="mc-table-wrapper products-table mt-large" *ngIf="selectedProduct.id > 0">
      <div class="mc-table">
        <table class="w-100">
          <thead class="mc-table-head">
          <tr>
            <th class="text-left">{{'cc.consumer.art-no' | translate}}</th>
            <th class="text-left">{{'cc.consumer.art-name' | translate}}</th>
            <th class="text-left">{{'cc.common.view.description' | translate}}</th>
            <th class="text-right">{{'cc.consumer.price-netto' | translate}}</th>
            <th class="text-right">{{'cc.product.view.vat' | translate}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="text-left">{{selectedProduct.id}}</td>
            <td class="text-left">{{selectedProduct.name}}</td>
            <td class="text-left">{{selectedProduct.description}}</td>
            <td class="text-right">{{selectedProduct.fldUnitPriceLoc()}}</td>
            <td class="text-right">{{selectedProduct.vatRate.toFixed(2).replace('.', ',')}}%</td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <button type="button" class="primary-btn ml-small" (click)="addService(selectedProduct)">
          {{ 'cc.common.table.add' | translate }}
        </button>
      </div>
    </div>

    <div class="mc-table-wrapper products-table"
         [class.mt-large]="!contractId"
         [class.mb-medium]="!contractId"
         *ngIf="selectedProducts.length > 0">
      <div class="mc-table" [class.update_mc-table]="contractId">
        <table class="w-100">
          <thead class="mc-table-head">
          <tr>
            <th class="text-left">{{'cc.consumer.art-no' | translate}}</th>
            <th class="text-left">{{'cc.consumer.art-name' | translate}}</th>
            <th class="text-left">{{'cc.common.view.description' | translate}}</th>
            <th class="text-right">{{'cc.consumer.price-netto' | translate}}</th>
            <th class="text-right">{{'cc.product.view.vat' | translate}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor='let selectedProduct of selectedProducts'>
            <td class="text-left">{{selectedProduct.id}}</td>
            <td class="text-left">{{selectedProduct.name}}</td>
            <td class="text-left">{{selectedProduct.description}}</td>
            <td class="text-right">{{selectedProduct?.unitPrice?.toFixed(2)}}€</td>
            <td class="text-right">{{selectedProduct.vatRate ? selectedProduct.vatRate.toFixed(2).replace('.', ',') : '0'}}%</td>
            <td *ngIf="contractId else removeServiceSection"></td>
            <ng-template #removeServiceSection>
              <td>
                <div *ngIf='!contractId' class='quantity-div'>
                  <button type='button' class='removeProduct' (click)='removeService(selectedProduct)'><span [innerHTML]="iconRemove | safeHtml"></span></button>
                </div>
              </td>
            </ng-template>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ng-container *ngIf="!contractId && selectedProducts.length > 0">
      <div class="divider"></div>
      <h2 class="title-medium-dark mb-large">{{'cc.common.discount' | translate}}</h2>
      <h3 class='body-text-medium-bold mb-small'>{{'mem.common.add-discount' | translate}}*</h3>
      <div class='input-wrapper'>
        <mat-radio-group formControlName="membershipDiscountBtn">
          <mat-radio-button [value]="true" class='mr-large' (change)='discountChange($event)'>{{'cc.common.edit.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false" (change)='discountChange($event)'>{{'cc.common.edit.no' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class='input-wrapper mt-large' *ngIf='isDiscountChecked'>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.discount' | translate}}*</mat-label>
          <mat-select panelClass="matSelect"
                      formControlName="discount"
                      (ngModelChange)="discountValueChange()"
                      disableOptionCentering>
            <mat-option *ngFor="let discount of discountList" [value]="discount">
              {{ discount.value }}
            </mat-option>
          </mat-select>
        </mat-form-field> <span class='flex-align-center body-text-medium'>%</span>
      </div>
    </ng-container>
    <div class="divider"></div>

    <!--  Duration  -->
    <h2 class="title-medium-dark mb-large">{{'cc.contract-360.duration' | translate}}</h2>
    <ng-container *ngIf='!contractId else editMembershipDurtion'>
      <div class='input-wrapper'>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.contract-360.duration' | translate}}*</mat-label>
          <mat-select panelClass="matSelect"
                      formControlName="durationInMonths"
                      (ngModelChange)="durationInMonthsChange()"
                      disableOptionCentering>
            <mat-option *ngFor="let duration of membershipDurations" [value]="duration.value">
              {{ duration.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-template #editMembershipDurtion>
      <div class='update_values' *ngIf='loadedMembership'>
        <ng-container>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{'cc.contract-360.duration' | translate}}:</span>
            <span class='dialog-col-value'>
              {{ loadedMembership.durationInMonths ? (loadedMembership.durationInMonths + ' months') : '-' }}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-template>
    <div class="divider"></div>

    <!--  Offer duration  -->
    <h2 class="title-medium-dark mb-large">{{'cc.contract-360.offer-duration' | translate}}</h2>
    <div class="mb-medium input-wrapper" [class.update_dates]="contractId">
      <ng-container *ngIf='!contractId else editMembershipStartDate'>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.factoring.start-date' | translate}}*</mat-label>
          <input matInput [matDatepicker]="membershipDurationStartDate" formControlName='startDate'
                 (click)='membershipDurationStartDate.open()' [min]='todayDate' (dateChange)="onStartChange($event)" readonly>
          <mat-datepicker-toggle matSuffix [for]="membershipDurationStartDate"></mat-datepicker-toggle>
          <mat-datepicker #membershipDurationStartDate></mat-datepicker>
        </mat-form-field>
      </ng-container>

      <ng-template #editMembershipStartDate>
        <div class='update_values flex-align-center' *ngIf='loadedMembership'>
          <div class='dialog-col'>
            <span class='dialog-col-label'>{{'cc.factoring.start-date' | translate}}:</span>
            <span class='dialog-col-value'>{{loadedMembership.startDate | date : "dd.MM.YYYY"}}</span>
          </div>
        </div>
      </ng-template>

      <mat-form-field appearance="outline" [ngClass]='!contractId ? "" : "w-400 end-date-form-field"'>
        <mat-label>{{'cc.factoring.end-date' | translate}}</mat-label>
        <input matInput [matDatepicker]="membershipDurationEndDate" formControlName='endDate'
               (click)='membershipDurationEndDate.open()' [min]='chosenStartDate' readonly>
        <mat-datepicker-toggle matSuffix [for]="membershipDurationEndDate"></mat-datepicker-toggle>
        <mat-datepicker #membershipDurationEndDate></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="content-footer">
      <button mat-button class="primary-btn"
              [disabled]="!form.valid"
              (click)="openCreateDialog()">
        {{(!contractId ? 'cc.common.create' : 'cc.common.view.save') | translate}}
      </button>
    </div>
  </div>
</form>
<div style="height: 10px"></div>
